import React from 'react';
import { Typography, useTheme, BoxProps, Stack, Box, Button } from '@mui/material';
import Container from 'components/container';
import { Image, MarkDown } from 'components';

interface TestimonialProp {
  recommendation: string;
  image?: string;
  orgLogo?: string;
  name: string;
  role: string;
  org: string;
  postscript?: string;
  ctaLabel?: string;
  href?: string;
}

const TestimonialNew = ({ recommendation, image, name, role, org, orgLogo, postscript, ctaLabel, href, ...boxProps }: TestimonialProp & BoxProps): JSX.Element => {
  const { typography } = useTheme();

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: image ? { xs: '1fr', md: '5fr 3fr', xl: '8fr 4fr' } : '1fr',
        alignItems: { xs: 'flex-start', xl: 'center' },
        gap: 0.5
      }}
      {...boxProps}
    >
      <Container>
        <Stack
          sx={{
            gap: 4,
            p: { xs: 1, md: 4.5, xl: 6.5 },
            pt: { xs: 8, md: 6.5 },
          }}
          position="relative"
        >
          <Typography
            sx={{
              color: 'rgba(59, 130, 246, 0.48)',
              fontSize: 72,
              fontStyle: 'normal',
              fontWeight: 400,
              fontFamily: 'Merriweather',
              lineHeight: 1.25,
              position: 'absolute',
              top: 0,
              left: 0
            }}
          >
            “
          </Typography>
          <MarkDown
            sx={{
              fontSize: { xs: typography.lg.fontSize, md: typography['xl'].fontSize, xl: typography['2xl'].fontSize },
              fontWeight: typography.medium
            }}
            color="primary.900"
            text={recommendation}
          />
          <Typography
            sx={{
              color: 'rgba(59, 130, 246, 0.48)',
              fontSize: 72,
              fontStyle: 'normal',
              fontWeight: 400,
              fontFamily: 'Merriweather',
              lineHeight: 1.25,
              position: 'absolute',
              bottom: { xs: '-64px', md: '-48px', xl: '-16px' },
              right: 0
            }}
          >
          ”
          </Typography>
        </Stack>
        <Stack
          sx ={{
            pt: { xs: 4, md: 0 },
            pl: { xs: 1, md: 4.5, xl: 6.5 }
          }}
        >
          <Typography
            color="primary.900"
            sx={{
              fontSize: { xs: typography.base.fontSize, md: typography.base.fontSize, xl: typography.lg.fontSize },
              ...typography.regular
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: typography.base.fontSize, md: typography.base.fontSize, xl: typography.lg.fontSize },
                ...typography.semibold
              }}
              component="span"
            >
              - {name},
            </Typography>
            &nbsp;{role}, {org}
          </Typography>
          {!!postscript && (
            <MarkDown
              sx={{
                ...typography.base,
                ...typography.regular,
                display: { xs: 'none', xl: 'block' },
                mt: '16px'
              }}
              color="primary.900"
              text={postscript}
            />
          )}
          {!!ctaLabel && (
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{
                width: 'fit-content',
                display: { xs: 'none', md: 'block' },
                mt: '16px'
              }}
              href={href}
            >
              {ctaLabel}
            </Button>
          )}
        </Stack>
      </Container>


      <Container>
        <Stack gap={2}>
          {!!image && (
            <Box position="relative">
              <Image
                src={image}
                alt='user image'
                sx={{
                  width: { xs: 0.75, xl: 0.8 }
                }}
                imageProps={{
                  style: {
                    objectFit: 'cover',
                    mixBlendMode: 'darken',
                    borderRadius: 12,
                    aspectRatio: 1
                  },
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: -80,
                  px: 3,
                  py: 2.5,
                  m: 2,
                  width: { xs: 192, xl: 216 },
                  height: 96,
                  borderRadius: 3,
                  border: '1px solid #E2E8F0',
                  background: 'white'
                }}
              >
                <Image
                  src={orgLogo}
                  alt='user org image'
                />
              </Box>
            </Box>
          )}
          {!!postscript && (
            <MarkDown
              sx={{
                ...typography.base,
                ...typography.regular,
                display: { xl: 'none' },
                mt: 8
              }}
              color="primary.900"
              text={postscript}
            />
          )}
          {!!ctaLabel && (
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{
                width: 'fit-content',
                display: { md: 'none' }
              }}
            >
              Reach case study
            </Button>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default TestimonialNew;
