import React from 'react';
import Main from 'layouts/main';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import Hero from './hero';
import { SectionFeatureMedium, AskIndmenButton, Faq, SectionVoice, TestimonialNew } from 'views/components';
import Partners from './partners';
import { GridContainer, Container } from 'components';
import { Typography, useTheme, Button } from '@mui/material';
import { FiMessageCircle, FiArrowRight } from 'react-icons/fi';
import * as constants from 'utils/constants';
import { generateMetaTags } from '../pageHelmet';

const mediumFeatures1 = [
  {
    title: 'Omni-channel setup to deliver instant responses',
    content:
      'Deliver a game-changing experience with AI that provides a seamless conversational interface across all channels.',
    media: 'md-omnichannel_rbcbfa',
  },
  {
    title: 'Deploy contextually across distribution channels',
    content:
      'Our AI agents adapt to your workflow, and recommend contextually appropriate responses based on the conversation.',
    media: 'md-distribution_iper0t',
  },
];
const mediumFeatures2 = [
  {
    title: 'Modernize interactions with clients and partners',
    content:
      'Deliver immediate access to products and information with AI-driven communication. Enable 24/7 availability and continuous improvement, all tailored to your business needs.',
    media: 'md-multiple-kbs_tempe0',
  },
  {
    title: 'Proven AI Agents, built to evolve with your business',
    content:
      'Start with proven AI agents that are already delivering results across the industry. Use our AI Studio to customize agents capable of mastering your business’s most complex workflows.',
    media: 'lg-broker-portal-assistant_qbyalj',
  },
];

const faqs = [
  {
    faq_intent: 'What does Indemn provide?',
    question: 'What does Indemn provide?',
  },
  {
    faq_intent: 'How do you help insurers?',
    question: 'How do you help insurers?',
  },
  {
    faq_intent: 'How would this improve my website?',
    question: 'How would this improve my website?',
  },
  {
    faq_intent: 'How do your AI Agents improve?',
    question: 'How do your AI Agents improve?',
  },
  {
    faq_intent: 'How does the technology work?',
    question: 'How does the technology work?',
  },
  {
    faq_intent: 'How does this impact sales?',
    question: 'How does this impact sales?',
  },
];

const Home = (): JSX.Element => {
  const { typography } = useTheme();
  return (
    <ChatbotStoreProvider>
      <Main isExtended>
        {generateMetaTags(
          constants.PAGE_CONTENT.HOME_PAGE.TITLE,
          constants.PAGE_CONTENT.HOME_PAGE.DESCRIPTION,
          constants.PAGE_CONTENT.HOME_PAGE.OG_IMAGE,
          window.location.href,
          constants.PAGE_CONTENT.HOME_PAGE.KEYWORDS,
        )}
        <Hero />
        <Partners />
        <GridContainer>
          <TestimonialNew
            recommendation='Initially, we planned to test Indemn’s chatbot for a week, so we could work out any bugs. **Our test went so well** that we left it on our site, and we haven’t looked back.'
            name='Juan Carlos Diaz-Padron'
            role='Chief Underwriting Officer'
            org='GIC Underwriters, Inc'
            image='https://res.cloudinary.com/deksgbacz/image/upload/v1736477184/customer-rep-gic-juan_vmau1j.png'
            orgLogo={constants.CLOUDINARY_INDEMN_LOGOS.CUSTOMER_GIC_LOGO}
            postscript='Within 60 days of implementing Indemn’s Platform, *GIC saw 46% of broker queries handled fully autonomously*, that would typically be asked over email, phone, and live chat.'
            ctaLabel='Read about GIC’s implementation of Indemn'
            href="/case-studies/gic-underwriters"
          />
        </GridContainer>
        <SectionFeatureMedium features={mediumFeatures1} />
        <SectionVoice />
        <Faq faqs={faqs} />
        <SectionFeatureMedium features={mediumFeatures2} />
        <GridContainer>
          <Container
            display="flex"
            flexDirection="column"
            gap={4}
            width={{ xs: 1, md: 6 / 8, xl: 6 / 12 }}
          >
            <Typography
              color="primary.900"
              sx={{ ...typography['2xl'], ...typography.bold }}
            >
              Looking for help scoping a project?
            </Typography>
            <Typography
              variant="h5"
              color="primary.900"
              sx={{ ...typography.base, ...typography.regular }}
            >
              We’re at the forefront of a technology revolution. As experts in
              Generative AI, we’ll help you navigate what works and what
              doesn’t. Every company should both build and buy generative AI
              solutions, and we’re here to guide you in identifying what’s
              achievable internally and where transformation is truly possible.
            </Typography>
            <Button
              variant="contained"
              endIcon={<FiArrowRight />}
              size="medium"
              color="secondary"
              sx={{ width: 'fit-content' }}
              href="/pricing"
            >
              {constants.PAGE_CONTENT.GLOBAL_CTA_LABEL.PRICING}
            </Button>
            {/* <AskIndmenButton
              variant="contained"
              endIcon={<FiMessageCircle />}
              size="large"
              sx={{ width: 'fit-content' }}
            >
              Talk to us
            </AskIndmenButton> */}
          </Container>
        </GridContainer>
      </Main>
    </ChatbotStoreProvider>
  );
};

export default Home;
