import React from 'react';
import Main from 'layouts/main';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import { useTheme, Typography, Box, Button } from '@mui/material';
import { FiMessageCircle, FiArrowRight } from 'react-icons/fi'; // Importing react-icons
import { AskIndmenButton, Faq, BotHostContainer } from 'views/components';
import { isMobile } from 'utils/helper-generic';
import {
  GridContainer,
  Container,
  MarkDown,
  Image,
  CloudVideo,
  CloudImage,
} from 'components';
import ChatLoader from 'context/chatLoader';
import { SectionFeatureLarge, SectionFeatureMedium } from 'views/components';
import * as constants from 'utils/constants';
import { generateMetaTags } from 'views/pageHelmet';

// const features = [
//   { id: 'copilot-feature-01', title: 'Real-time handoff to agents', content: 'Let agents pick up conversations in real-time, providing for a smooth transitional experience for your customers.  Yes, mobile conversations remain mobile.' },
//   { id: 'copilot-feature-02', title: 'AI-based recommendations', content: 'Leverage GPT-4 for your team to deliver recommendations, saving agents and your customers precious time, and giving an impression of a blazing fast service.' },
//   { id: 'copilot-feature-03', title: 'Native quotes and payments', content: 'Let your agents leverage quotes and payments within the conversation itself, creating a seamless experience for your customers.' }
// ];

const faqs = [
  {
    faq_intent: 'What does Indemn provide?',
    question: 'What does Indemn provide?',
  },
  {
    faq_intent: 'How do you help insurers?',
    question: 'How do you help insurers?',
  },
  {
    faq_intent: 'How would this improve my website?',
    question: 'How would this improve my website?',
  },
  {
    faq_intent: 'How do your AI Agents improve?',
    question: 'How do your AI Agents improve?',
  },
  { faq_intent: 'What is a Copilot?', question: 'What is a Copilot?' },
  {
    faq_intent: 'How do you loop in a human?',
    question: 'How do you loop in a human?',
  },
];

const mediumFeatures1 = [
  {
    title: 'Live intervention',
    content:
      'Make use of our expert AI agent building team and proven methods for delivering solutions to market.',
    media: 'copilot-hero-img',
  },
  {
    title: 'AI recommendations',
    content:
      'Save significant time and improve accuracy with AI recommendations informed by your policies, procedures and product information.',
    media: 'md-ai-recommendations_euygim',
  },
];
const mediumFeatures2 = [
  {
    title: 'Integrated quotes and payments',
    content:
      'Allow your CSRs to leverage our dynamic APIs to fetch quotes and payments from within the Copilot, keeping your users experience uninterrupted.',
    media: 'md-csr-quotes-payments_znxlr6',
  },
  {
    title: 'AI agents improve over time',
    content:
      'Improve your AI Agents over time with Reinforcement Learning from Human Feedback (RLHF). Provide your AI Agents with the specific knowledge and voice that matches your best CSRs.',
    media: 'md-rlhf_e66nrb',
  },
];

const mediumFeatures3 = [
  {
    title: 'Handoffs across all channels',
    content:
      'The Agent Copilot serves as a nexus to enable CSRs pick up conversations across all channels, paving the path for a true digital transformation.',
    media: 'md-omnichannel_rbcbfa',
  },
  {
    title: 'Built-in analytics to make conversations better',
    content:
      'Leverage our built-in analytics to understand how and why conversations are handed off, letting you further optimize towards your team’s productivity.',
    media: 'md-analytics_d6xq6i',
  },
];

const Copilot = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <ChatbotStoreProvider>
      <Main isExtended>
        {generateMetaTags(
          constants.PAGE_CONTENT.PRODUCTS_COPILOT.TITLE,
          constants.PAGE_CONTENT.PRODUCTS_COPILOT.DESCRIPTION,
          constants.PAGE_CONTENT.PRODUCTS_COPILOT.OG_IMAGE,
          window.location.href,
          constants.PAGE_CONTENT.PRODUCTS_COPILOT.KEYWORDS,
        )}
        <GridContainer pt={20} pb={2}>
          <Container
            display="flex"
            flexDirection="column"
            margin="0 auto"
            gap={4}
            width={{ xs: 1, md: 7 / 8, xl: 6 / 12 }}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Image
                src={constants.CLOUDINARY_INDEMN_LOGOS.PRODUCT_COPILOT_LOGO}
                alt="copilot-logo"
                width={32}
                height={32}
                imageProps={{
                  style: {
                    objectFit: 'cover',
                  },
                }}
              />
              <Typography
                color="primary.800"
                sx={{ ...typography.base, ...typography.semibold }}
              >
                Agent Copilot
              </Typography>
            </Box>
            <MarkDown
              sx={{
                ...typography['4xl'],
                ...typography.bold,
                whiteSpace: 'pre-line',
              }}
              text="Humans + AI: The Future of Insurance "
            />
            <MarkDown
              sx={{ ...typography.base, ...typography.regular }}
              text="Agent Copilot recommends responses during live conversations, ensuring seamless communication with customers and partners. With every interaction, the AI learns and improves, making your customer service smarter and more efficient."
            />
            <Button
              variant="contained"
              endIcon={<FiArrowRight />}
              size="large"
              color="primary"
              sx={{ width: 'fit-content' }}
              href="/pricing"
            >
              {constants.PAGE_CONTENT.GLOBAL_CTA_LABEL.MAIN}
            </Button>
            {/* <AskIndmenButton
              variant="contained"
              endIcon={<FiMessageCircle />}
              size="large"
              sx={{ width: 'fit-content' }}
              message="I’m interested in Indemn’s Agent Copilot"
            >
              Learn more
            </AskIndmenButton> */}
          </Container>
        </GridContainer>

        <Box position="relative">
          <GridContainer pt={{ xs: 4, md: 6 }} zIndex={2}>
            <Box
              width={{ xs: 1, xl: 10 / 12 }}
              margin="0 auto"
              sx={{ '& video': { width: 1 } }}
            >
              {isMobile() ? (
                <CloudImage id="copilot-hero-img" />
              ) : (
                <CloudVideo id="copilot-hero" />
              )}
            </Box>
          </GridContainer>
          {/* hero background */}
          <Image
            src={constants.CLOUDINARY_IMAGES.PRODUCT_HERO_IMAGE}
            alt="copilot-hero-image"
            position="absolute"
            top={0}
            zIndex={1}
            imageProps={{
              style: {
                opacity: 0.64,
                objectFit: 'cover',
                mixBlendMode: 'darken',
                filter: 'blur(8px)',
              },
            }}
          />
        </Box>
        <BotHostContainer width={{ xs: 1, xl: 8 / 12 }} />

        {/* itc-2024: replace this with large features */}

        <SectionFeatureMedium features={mediumFeatures1} />

        <SectionFeatureLarge
          title="Contextualize conversations to your CSRs for handoff"
          content="The Copilot is built-in with strong summarization, categorization, and sentiment analysis capabilities to help the CSR get up to speed on the conversation, in as little time as possible."
          media="lg-sentiment_ehekxi"
        />

        <SectionFeatureMedium features={mediumFeatures2} />

        <SectionFeatureMedium features={mediumFeatures3} />

        {/* {features.map(feature => (
          <GridContainer key={feature.id} display='flex' flexDirection='column' alignItems='center' gap={4}>
            <Box
              sx={{
                width: 1,
                borderRadius: 3,
                border: `1px solid ${palette.primary[200]}`,
              }}
            >
              <Container width={{ xs: 1, md: 6 / 8, xl: 6 / 12 }} gap={2} margin='0 auto' display='flex' flexDirection='column'>
                <Typography color='primary.900' sx={{ ...typography['2xl'], ...typography.bold }}>
                  {feature.title}
                </Typography>
                <Typography color='primary.900' sx={{ ...typography.base, ...typography.regular, mixBlendMode: 'darken' }}>
                  {feature.content}
                </Typography>
              </Container>
            </Box>
            <Box width={{ xs: 1, xl: 8 / 12 }} margin="0 auto">
              <CloudImage id={`${feature.id}-img`} />
            </Box>
          </GridContainer>
        ))} */}
        <Faq faqs={faqs} />
        <ChatLoader />
      </Main>
    </ChatbotStoreProvider>
  );
};

export default Copilot;
