import React from 'react';
import { useTheme, Typography, Stack } from '@mui/material';
import { GridContainer, Container, MarkDown } from 'components';
import { TestimonialNew } from 'views/components';

const News = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <GridContainer
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 6, md: 8 },
      }}
    >
      <Stack
        sx={{
          width: { xs: 1, xl: 10 / 12 },
        }}
      >
        <Container>
          <MarkDown
            sx={{ ...typography.base, ...typography.regular }}
            color="primary.900"
            text={`**BOSTON, MASSACHUSETTS - January 06, 2025** - Indemn worked closely with GIC to convert their **marketing materials, appetite documents**, and **company resources** into a finely tuned, insurance-specific knowledge base.  Using just a single line of code, GIC published an AI agent, built with Indemn’s [AI Studio](/), for their producers to interact with.
>\nAs GIC’s producers typed questions into the chatbot, the AI agent would search from the knowledge base to generate responses.  GIC also implemented Indemn’s [Copilot](/), so that if a question fell outside of the knowledge base or **if a human with deeper expertise was really needed**, the AI agent handed the conversation to one of GIC’s CSRs to immediately help.
>\nWithin 7 days, Indemn partnered with GIC to test and tweak the behavior of the AI agent as well as to prioritize any custom requirements that GIC deemed necessary for go-live.  Indemn also provided GIC with tools within the Copilot to provide real-time feedback by tweaking or editing recommendations generated by the AI agent or to review historical responses.`}
          />
        </Container>

        <TestimonialNew
          recommendation='It was very important for us that if someone needed to talk to a human, the system could connect them with a CSR fast. With Indemn’s platform there was no pushback, no frustration - just immediate help when needed'
          name='Juan Carlos Diaz-Padron'
          role='Chief Underwriting Officer'
          org='GIC Underwriters, Inc'
          mt={4}
        />
      </Stack>

      <Stack
        sx={{
          width: { xs: 1, xl: 10 / 12 },
        }}
      >
        <Container>
          <Typography
            color="primary.900"
            sx={{ ...typography['2xl'], ...typography.bold }}
          >
            About Indemn
          </Typography>
        </Container>
        <Container>
          <MarkDown
            sx={{ ...typography.base, ...typography.regular }}
            color="primary.900"
            text={`**GIC Underwriters Inc**. is a licensed surplus lines agency serving  Arizona, Florida, Georgia, and Texas with personal and commercial  insurance solutions. With over 50 years of experience, the firm  attributes its success to exceptional service and product offerings  while embracing technology to better serve brokers and consumers.
>\nTo maintain its exceptional customer service at scale, GIC deployed Indemn’s Broker Portal Assistant to autonomously **resolve 46% of broker inquiries via email, phone, and live chat in just 60 days.**`}
          />
        </Container>
      </Stack>

      <Stack
        sx={{
          width: { xs: 1, xl: 10 / 12 },
        }}
      >
        <Container>
          <Typography
            color="primary.900"
            sx={{ ...typography['2xl'], ...typography.bold }}
          >
            About Millenial Shift Technologies
          </Typography>
        </Container>
        <Container>
          <MarkDown
            sx={{ ...typography.base, ...typography.regular }}
            color="primary.900"
            text={`**GIC Underwriters Inc**. is a licensed surplus lines agency serving  Arizona, Florida, Georgia, and Texas with personal and commercial  insurance solutions. With over 50 years of experience, the firm  attributes its success to exceptional service and product offerings  while embracing technology to better serve brokers and consumers.
>\nTo maintain its exceptional customer service at scale, GIC deployed Indemn’s Broker Portal Assistant to autonomously **resolve 46% of broker inquiries via email, phone, and live chat in just 60 days.**`}
          />
        </Container>
      </Stack>
    </GridContainer>
  );
};

export default News;
