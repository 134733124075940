// BotHostContainer.tsx
import React, { useContext, useEffect } from 'react';
import { Container, GridContainer } from 'components';
import { ChatbotContext } from 'context/chatbot-state';
import { BoxProps } from '@mui/material';

const BotHostContainer: React.FC<BoxProps> = ({ ...boxProps }) => {
  const { chatbotState } = useContext(ChatbotContext);

  useEffect(() => {
    if (chatbotState.isAskIndemnActive) {
      console.log('The askIndemnButton was clicked!');
      // Perform any additional actions when askIndemnButton is active
    }
  }, [chatbotState.isAskIndemnActive]);

  return (
    <GridContainer pb={chatbotState.isAskIndemnActive ? { xs: 12, md: 16 } : 0} {...boxProps}>
      <Container
        id="indemn-webchat"
        height={'80vh'}
        display={chatbotState.isAskIndemnActive ? 'block' : 'none'}
        px={0}
        py={{ xs: 1.5, md: 2, xl: 2.5 }}
      />
    </GridContainer>
  );
};

export default BotHostContainer;
