import React from 'react';
import { Typography, Box, Stack, useTheme } from '@mui/material';

import Main from 'layouts/main';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import ChatLoader from 'context/chatLoader';
import * as constants from 'utils/constants';

import { GridContainer, Container, MarkDown, Image } from 'components';
import { Faq, BotHostContainer, SectionFeatureText, TestimonialNew } from 'views/components';
import { Hero, PurpleBox } from './components';

const faqs = [
  {
    faq_intent: 'Will it also take me 7 days to go live with Indemn?',
    question: 'Will it also take me 7 days to go live with Indemn?',
  },
  {
    faq_intent: 'What will I need to set up my broker portal assistant?',
    question: 'What will I need to set up my broker portal assistant?',
  },
  {
    faq_intent: 'How much does a subscription to the Indemn platform cost?',
    question: 'How much does a subscription to the Indemn platform cost?',
  },
  {
    faq_intent: 'What kind of support will I get after signing up for the Indemn platform?',
    question: 'What kind of support will I get after signing up for the Indemn platform?',
  },
  {
    faq_intent: 'I only have prior recordings of phone calls.  Will that work as a knowledge base?',
    question: 'I only have prior recordings of phone calls.  Will that work as a knowledge base?',
  },
  {
    faq_intent: 'Do you provide AI agents for phone communications?',
    question: 'Do you provide AI agents for phone communications?',
  },
];

const features = [
  {
    feature: '7',
    body:
      'Days to go live - setting up the body of knowledge, testing, custom functionality',
  },
  {
    feature: '46%',
    body:
      'Producer inquiries autonomously handled by the AI agent in the first 60 days of implementation',
  },
  {
    feature: '95%',
    body:
      'Accuracy of responses reached in the first 60 days of implementation',
  },
];

const backgroundItems = [
  'Maintain high standard of service',
  'Demonstrate superior market knowledge',
  'Be an early adopter of technology'
];

const GicCaseStudy = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <ChatbotStoreProvider>
      <Main>
        {/* hero */}
        <Hero
          title="CASE STUDY"
          description="GIC Underwriters **Automates 46% of Broker Questions in 60 Days** with Indemn's AI Agents, Freeing Underwriters to Focus on Growth"
          orgLogo={constants.CLOUDINARY_INDEMN_LOGOS.CUSTOMER_GIC_LOGO}
        />
        {/* features */}
        <GridContainer
          display="flex"
          justifyContent="center"
          sx={{
            pt: '16px',
            pb: '16px'
          }}
        >
          <Box width={{ xs: 1, xl: 10 / 12 }}>
            <SectionFeatureText
              features={features}
            />
          </Box>
        </GridContainer>
        {/* summary */}
        <GridContainer
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 6, md: 8 },
            pt: '16px',
            pb: '16px'
          }}
        >
          <Box
            sx={{
              width: { xs: 1, xl: 10 / 12 },
              display: 'flex',
              flexDirection: { xs: 'column', xl: 'row' },
              gap: { xs: 4, xl: 0.5 },
            }}
          >
            <Stack
              sx={{
                width: 1
              }}
            >
              <Container>
                <Typography
                  color="primary.900"
                  sx={{ ...typography['2xl'], ...typography.bold }}
                >
                  Summary
                </Typography>
              </Container>
              <Container>
                <MarkDown
                  sx={{ ...typography.base, ...typography.regular }}
                  color="primary.900"
                  text={`**GIC Underwriters** leveraged Indemn's Generative AI-powered [Broker Portal Assistant](/solutions/broker-portal) to automate 46% of routine inquiries in just two months. This freed underwriters and CSRs to focus on complex risks and new business, while providing brokers with instant, 24/7 access to accurate coverage and appetite information. Indemn's [human-in-the-loop approach](/products/agent-copilot) minimized AI errors and ensured seamless escalation to live agents.
>\nUsing [Indemn's AI Studio and APIs](/products/ai-studio), GIC is now refining workflows, expanding AI capabilities to phone and email, and developing automated quoting for high-volume products. The result? **A more efficient, data-driven operation poised for sustainable growth.**`}
                />
              </Container>
            </Stack>
          </Box>
          <Container
            sx={{
              width: { xs: 1, xl: 10 / 12 }
            }}
          >
            <TestimonialNew
              recommendation='Initially, we planned to test Indemn’s chatbot for a week, so we could work out any bugs. **Our tests went so well** that we left it on our site, and we haven’t looked back.'
              name='Juan Carlos Diaz-Padron'
              role='Chief Underwriting Officer'
              org='GIC Underwriters'
              image='https://res.cloudinary.com/deksgbacz/image/upload/v1736477184/customer-rep-gic-juan_vmau1j.png'
              orgLogo={constants.CLOUDINARY_INDEMN_LOGOS.CUSTOMER_GIC_LOGO}
            />
          </Container>
        </GridContainer>
        {/* study */}
        <GridContainer
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 6, md: 8 },
            pt: '16px',
            pb: '16px'
          }}
        >
          <Stack
            sx={{
              width: { xs: 1, xl: 10 / 12 },
            }}
          >
            <Container>
              <Typography
                color="primary.900"
                sx={{ ...typography['2xl'], ...typography.bold }}
              >
                Background: 30 Years of Superior Service
              </Typography>
            </Container>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: { xs: 3, md: 0.5 },
              }}
            >
              <Stack
                sx={{
                  width: { xs: 1, md: 1 / 2, xl: 2 / 3 }
                }}
              >
                <Container>
                  <MarkDown
                    sx={{ ...typography.base, ...typography.regular }}
                    color="primary.900"
                    text={`**GIC Underwriters** is a licensed wholesale broker and managing general agent (MGA) offering personal and commercial lines products in Arizona, Florida, Georgia, and Texas.
>\nGIC attributes its 30 years of success to superior service, product offerings, and a commitment to meeting the needs of its producers.
>\nThe company prides itself on its market knowledge and for being an early adopter of technology, so it can provide a better experience to brokers and consumers.`}
                  />
                </Container>
              </Stack>
              <Stack
                sx={{
                  width: { xs: 1, md: 1 / 2, xl: 1 / 3 },
                  gap: 1
                }}
              >
                {backgroundItems.map(item => (
                  <Container
                    key={item}
                    sx={{
                      borderRadius: 2,
                      border: '1px solid #E0E8FF',
                      background: '#F0F3FF',
                      display: 'flex',
                      gap: 3,
                      alignItems: 'center'
                    }}
                  >
                    <Image
                      src='/popular.svg'
                      alt={'jon'}
                    />
                    <Typography
                      color="primary.900"
                      sx={{ ...typography.base, ...typography.medium }}
                    >
                      {item}
                    </Typography>
                  </Container>
                ))}
              </Stack>
            </Box>
          </Stack>

          <Stack
            sx={{
              width: { xs: 1, xl: 10 / 12 },
            }}
          >
            <Container>
              <Typography
                color="primary.900"
                sx={{ ...typography['2xl'], ...typography.bold }}
              >
                Challenge: Scaling GIC’s Exceptional Customer Service
              </Typography>
            </Container>
            <Container>
              <MarkDown
                sx={{ ...typography.base, ...typography.regular }}
                color="primary.900"
                text={'Every day GIC’s hard-working and highly skilled team of 6 customer service representatives (CSRs) respond to broker inquiries via phone, email, and live chat.'}
              />
            </Container>

            <PurpleBox
              content="While this team has deep industry expertise and knowledge of GIC’s programs, they often **spend countless hours answering the same basic questions** about risk appetite, quote generation, and coverage details."
            />

            <Container>
              <MarkDown
                sx={{ ...typography.base, ...typography.regular }}
                color="primary.900"
                text={'This problem becomes even more pronounced during peak hours. The routine, repetitive work pulls valuable resources away, which could have been used to answer more important questions about underwriting decisions and new business opportunities.'}
              // >\n"Service is king when it comes to helping consumers purchase insurance, and for brokers to sell it," explained Diaz-Padron.
              />
            </Container>

            <TestimonialNew
              recommendation='GIC needed a way to maintain our high service standards, while freeing our experts to focus on what they do best.'
              name='Juan Carlos Diaz-Padron'
              role='Chief Underwriting Officer'
              org='GIC Underwriters'
              mt={4}
            />
          </Stack>

          <Stack
            sx={{
              width: { xs: 1, xl: 10 / 12 },
            }}
          >
            <Container>
              <Typography
                color="primary.900"
                sx={{ ...typography['2xl'], ...typography.bold }}
              >
                Solution: Using AI to Take GIC’s High Service Standards to the Next Level
              </Typography>
            </Container>
            <Container>
              <MarkDown
                sx={{ ...typography.base, ...typography.regular }}
                text={`Before engaging with Indemn, GIC had already built a successful chat system, but they knew artificial intelligence could take it further. After evaluating several AI solutions, they found most fell short on two critical needs:\n 
- Providing immediate and accurate responses to broker inquiries
- Routing escalations and questions outside of an AI agent’s knowledge base to human CSRs quickly
>\nAfter doing some research, GIC came across Indemn’s [Broker Portal Assistant](/solutions/broker-portal). “We were exploring adding an AI component internally, but Indemn had already done the heavy lifting with an out-of-the-box solution,” Diaz-Padron recalled.
>\nTo start, Indemn worked closely with GIC to convert their marketing materials, appetite documents, and company resources into a finely tuned, insurance-specific knowledge base.
>\nGIC implemented Indemn's AI agent into their broker portal with a single line of code. Testing in a sandbox environment lasted less than 7 days. Indemn's APIs enabled GIC to track individual agent interactions. Crucially, complex queries were automatically routed to human CSRs, ensuring a seamless and frustration-free experience for brokers. "Ensuring a smooth transition to a live agent for complex issues was paramount," Diaz-Padron states. "The system provides immediate assistance without any obstacles or frustration."
>\nTo keep improving, GIC took advantage of Indemn’s [Agent Copilot](/products/agent-copilot) feature, a human-in-the-loop mechanism allowing experts to join any ongoing AI-broker exchange. By providing real-time feedback to the AI, refining responses, and updating the knowledge base, the team continually sharpened the agent’s accuracy. This hands-on oversight, combined with ongoing data analysis, cut AI hallucinations by 95% and guaranteed that responses stayed closely aligned with GIC’s vetted information and business goals.`}
              />
            </Container>

            <TestimonialNew
              recommendation='It was very important for us that if someone needed to talk to a human, the system could connect them with a CSR fast. With Indemn’s platform there was no pushback, no frustration - just immediate help when needed.'
              name='Juan Carlos Diaz-Padron'
              role='Chief Underwriting Officer'
              org='GIC Underwriters'
              mt={4}
            />
          </Stack>

          <Stack
            sx={{
              width: { xs: 1, xl: 10 / 12 },
            }}
          >
            <Container>
              <Typography
                color="primary.900"
                sx={{ ...typography['2xl'], ...typography.bold }}
              >
                Results: Service Team Scales with Less and Generates More Revenue, While Agents Grow More Satisfied
              </Typography>
            </Container>
            <Container>
              <MarkDown
                sx={{ ...typography.base, ...typography.regular }}
                color="primary.900"
                text={`**The results were immediate.** In the first week, their AI agent answered 19% of all broker inquiries. After 60 days it answered 46% of incoming questions with 95% accuracy.
>\nIn addition to answering many common and repetitive inquiries, some of the other benefits GIC saw included:
- Higher service marks across the board
- Increased broker satisfaction due to 24/7 access to instant answers.
- Underwriters and CSRs freed to focus on complex underwriting and new business.`}
              />
            </Container>

            <PurpleBox
              content="Surprisingly, GIC’s AI agent **drove higher engagement with newly appointed producers** by efficiently answering questions about the fit for new business opportunities, helping those still learning GIC’s product appetite."
            />

            <TestimonialNew
              recommendation='While our customer service team was initially skittish about adding AI, they’re now enthusiastic supporters because **they have fewer chats to handle, and it allows them to focus on more meaningful work**'
              name='Juan Carlos Diaz-Padron'
              role='Chief Underwriting Officer'
              org='GIC Underwriters'
              mt={4}
            />

            <Container>
              <MarkDown
                sx={{ ...typography.base, ...typography.regular }}
                color="primary.900"
                text={`Every AI interaction is automatically categorized and summarized, providing GIC with valuable data on broker behavior, market trends, and emerging opportunities. Leveraging Indemn's [AI Studio and REST APIs](/products/ai-studio), GIC is building custom workflows based on the most frequent broker requests.
>\nGIC is already streamlining tasks like payment status checks and policy updates. Looking ahead in 2025, they plan to integrate Indemn’s AI agents across phone and email channels, and automate quoting for high-volume products. This is a strategic move to build a more scalable, resilient, and partner-centric business. By automating routine tasks, underwriters and CSRs can focus their expertise on complex underwriting and strategic growth, ultimately delivering greater value and ensuring long-term success.`}
              />
            </Container>
          </Stack>
        </GridContainer>

        <Faq faqs={faqs} />
        <BotHostContainer width={{ xs: 1, xl: 12 / 12 }} />
        <ChatLoader />
      </Main>
    </ChatbotStoreProvider>
  );
};

export default GicCaseStudy;
