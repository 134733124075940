import React from 'react';
import { Container } from 'components';
import { Stack, Typography, useTheme, Box } from '@mui/material';

interface TSectionFeatureSmall {
  feature: string;
  title?: string;
  body?: string;
}

const SectionFeatureText = ({
  features,
}: {
  features: TSectionFeatureSmall[];
}): JSX.Element => {
  const { typography, palette } = useTheme();

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr' },
        gap: { xs: 1.5, xl: 0.5 },
      }}
    >
      {features.map(({ title, feature, body }) => (
        <Container
          key={title}
          display="flex"
          flexDirection="column"
          gap={{ xs: 2.5, md: 4.5, xl: 4 }}
        >
          {!!feature && (
            <Typography
              color="primary.900"
              sx={{ ...typography['5xl'], ...typography.bold }}
            >
              {feature}
            </Typography>
          )}
          <Stack gap={2}>
            {!!title && (
              <Typography
                color="monochrome.gray.800"
                sx={{
                  ...typography.base,
                  ...typography.semibold,
                  borderLeft: `2px solid ${palette.primary[600]}`,
                  pl: { xs: 1, md: 2, xl: 3 },
                  ml: { xs: -1, md: -2, xl: -3 },
                }}
              >
                {title}
              </Typography>
            )}
            <Box
              sx={{
                position: 'relative',
                pl: { xs: 1, md: 2, xl: 3 },
                ml: { xs: -1, md: -2, xl: -3 },
              }}
            >
              <Typography
                color="monochrome.gray.600"
                sx={{
                  ...typography.base,
                  ...typography.regular,
                }}
              >
                {body}
              </Typography>
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  height: 18,
                  borderLeft: `2px solid ${palette.primary[600]}`,
                  display: title ? 'none' : 'block'
                }}
              />
            </Box>
          </Stack>
        </Container>
      ))}
    </Box>
  );
};

export default SectionFeatureText;
