import React from 'react';
import { Typography, TypographyProps, useTheme } from '@mui/material';
import Markdown from 'react-markdown';

interface Props {
  text: string;
  emColor?: string;
  strongColor?: string;
  aColor?: string;
}

const MarkDown = ({ text, emColor, aColor, strongColor, ...typographyProps }: Props & TypographyProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Typography
      variant='h4'
      color='primary.900'
      {...typographyProps}
      sx={{
        ...typographyProps.sx,
        '& p': {
          margin: 0
        },
        '& strong': {
          color: strongColor ?? 'inherit'
        },
        '& em': {
          color: emColor ?? palette.secondary[600],
          mr: 0.5
        },
        '& a': {
          color: aColor ?? palette.secondary[600],
        }
      }}
    >
      <Markdown>{text}</Markdown>
    </Typography>

  );
};

export default MarkDown;
