import React from 'react';
import { Button, Typography, Box, useTheme } from '@mui/material';
import { FiCheck, FiX, FiArrowRight } from 'react-icons/fi'; // Importing react-icons
import { Container, GridContainer, MarkDown } from 'components';
import AskIndmenButton from './ask-indemn-button';
import { FiMessageCircle } from 'react-icons/fi';

// dummy prices
interface Price {
  title: string;
  price?: string;
  description: string;
  covers: string[];
  notCovers: string[];
  recommended?: boolean;
  ctaLabel: string;
  messageToAgent?: string;
  href?: string;
}

const prices: Price[] = [
  {
    title: 'Starter',
    // be able to hide price on this and the enterprise
    // internationalize them
    // brief description of who this is ideal for
    description: 'For businesses ready to explore AI with targeted solutions',
    covers: [
      'Seamless handoff to a human via live chat',
      'Automate simple workflows like policy inquiries and FAQs',
      'Single communication channel - voice, webchat, text or email',
      'Limited offline support'
    ],
    notCovers: [
      'Summarize and categorize conversations to improve AI agent performance',
      'Advanced functions and integrations'
    ],
    recommended: false,
    ctaLabel: 'Talk to sales',
    messageToAgent: 'I\'m interested in a more starter plan'
  },
  {
    title: 'Early Adopter',
    price: '$2,000',
    description: 'Ready capabilities to start seeing tangible AI-powered efficiency in less than a week',
    covers: [
      'Seamless handoff to a human via live chat',
      'AI recommendations and summaries to help your team resolve inquiries faster',
      'Pre-built workflows like lead capture, application completion, service, policy APIs',
      'Integrate via REST API to your CRM, AMS, PAS of choice',
      'Summarize and categorize conversations to improve AI agent performance',
      'Whitelabel your AI agent to your brand and your partners\'',
      'L1 and L2 support available',
      '1000 free chat conversations included',
    ],
    notCovers: [],
    recommended: true,
    ctaLabel: 'Sign up now',
    href: 'https://copilot.indemn.ai/dashboard/#/signup/plan?planId=65e17d93b26fd2526e096cce'
  },
  {
    title: 'Enterprise',
    description: 'For businesses who\'d like to scope large-scale improvements in multiple areas at once',
    covers: [
      'Everything in Early Adopter +',
      '24/7 priority support',
      'Whiteglove support including building workflows for data and AI readiness',
      'Self-hosted options and private LLMs',
      'Dedicated solutions architect',
      'Custom integrations to other operational and analytical tools in your stack'
    ],
    notCovers: [],
    recommended: false,
    ctaLabel: 'Talk to sales',
    messageToAgent: 'I\'m interested in a more comprehensive build'
  }
];

const PriceCard = ({ ...data }: Price): JSX.Element => {
  const { typography, palette } = useTheme();
  const { title, price, covers, notCovers, recommended, messageToAgent, ctaLabel, description, href } = data;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'max-content max-content 1fr',
        position: 'relative',
        padding: '20px 16px',
        borderRadius: '12px',
        background: recommended ? '#172554' : palette.primary[50],
        border: `1px solid ${recommended ? palette.primary[500] : palette.primary[300]}`,
        gap: { xs: 2.5 },
        height: 'fit-content',
      }}
    >
      <Box
        display="flex"
        gap={1.5}
        flexDirection="column"
        alignItems={{ xs: 'flex-start', md: 'flex-start' }}
        pt={2}
      >
        <Typography
          color={recommended ? 'primary.50' : 'primary.800'}
          sx={{ ...typography.base, ...typography.semibold }}
        >
          {title}
        </Typography>
        <Typography
          color={recommended ? 'primary.50' : 'primary.800'}
          sx={{ ...typography.sm, ...typography.regular }}
        >
          {description}
        </Typography>
        {price
          ? (
            <Box
              display="flex"
              gap={1}
              flexDirection="column"
              alignItems={{ xs: 'flex-start' }}
              paddingTop={'12px'}
              paddingBottom={'12px'}
            >
              <Typography
                color={recommended ? 'white' : 'monochrome.gray.800'}
                sx={{ ...typography['3xl'], ...typography.bold }}
              >
                {price}
              </Typography>
              <Typography
                color={recommended ? 'monochrome.gray.100' : 'monochrome.gray.500'}
                sx={{ ...typography.xs, ...typography.regular }}
              >
                per month
              </Typography>
            </Box>
          )
          : <Box height='auto' />
        }
      </Box>

      {recommended && (
        <Button
          variant="contained"
          endIcon={
            <FiArrowRight size={20} />
          }
          size="medium"
          color={'secondary'}
          sx={{ width: { xs: 'fit-content', md: 1 } }}
          href={href}
        >
          {ctaLabel}
        </Button>
      )}

      {!recommended && (
        <AskIndmenButton
          variant="outlined"
          endIcon={<FiMessageCircle size={12} />}
          size="small"
          message={messageToAgent}
          sx={{
            mb: 1
          }}
        >
          {ctaLabel}
        </AskIndmenButton>
      )}

      <Box display="flex" flexDirection="column" gap={2} mt={2}>
        {covers.map((txt) => (
          <Box
            display="grid"
            gridTemplateColumns="16px 1fr"
            alignItems="flex-start"
            gap={1}
            key={txt}
          >
            <FiCheck size={16} color={palette.tertiary[500]} />{' '}
            {/* Replacing FeatherIcon with FiCheck */}
            <Typography
              color={recommended ? 'monochrome.gray.50' : 'monochrome.gray.700'}
              sx={{ ...typography.xs, ...typography.medium }}
            >
              {txt}
            </Typography>
          </Box>
        ))}

        {notCovers.map((txt) => (
          <Box
            display="grid"
            gridTemplateColumns="16px 1fr"
            alignItems="flex-start"
            gap={1}
            key={txt}
          >
            <FiX size={16} color={palette.monochrome.gray[500]} />{' '}
            {/* Replacing FeatherIcon with FiX */}
            <Typography
              color={recommended ? 'monochrome.gray.50' : 'monochrome.gray.700'}
              sx={{ ...typography.xs, ...typography.regular }}
            >
              {txt}
            </Typography>
          </Box>
        ))}
      </Box>
      {recommended && (
        <Box
          position="absolute"
          top={-4}
          right={{ xs: 'unset', md: -4 }}
          left={{ xs: -4, md: 'unset' }}
          bgcolor={palette.tertiary[200]}
        >
          <Typography
            color="tertiary.900"
            sx={{ ...typography.sm, ...typography.medium }}
            padding="4px 8px"
          >
            RECOMMENDED
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const Pricing = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <GridContainer>
      <Box
        sx={{
          display: { xs: 'flex', xl: 'grid' },
          gridTemplateColumns: { xs: 'unset', xl: '1fr 3fr' },
          flexDirection: { xs: 'column', xl: 'unset' },
          gap: { xs: 1.5, xl: 0.5 },
        }}
      >
        <Container width={{ xs: 1, md: 0.5, xl: 1 }}>
          <MarkDown
            sx={{ ...typography['2xl'], ...typography.bold }}
            paddingBottom={'24px'}
            text="Transform your insurance business with flexible, scalable AI solutions"
          />
          <Typography
            color="primary.900"
            sx={{ ...typography.base, ...typography.regular }}
            paddingBottom={'24px'}
          >
            Choose a plan that fits your needs, cancel anytime, and see results fast—whether you&apos;re scaling up or starting small.  <br /><br />Need a custom solution? Ask Indemn to tailor your plan.
          </Typography>
          <AskIndmenButton
            variant="outlined"
            endIcon={<FiMessageCircle size={12} />}
            size="small"
            message="I have a different build in mind"
          >
            Ask Indemn
          </AskIndmenButton>
        </Container>
        <Container
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr' },
            gap: { xs: 2.5, md: 4.5, xl: 6.5 },
          }}
        >
          {prices.map((price) => (
            <PriceCard {...price} key={price.title} />
          ))}
        </Container>
      </Box>
    </GridContainer>
  );
};

export default Pricing;
