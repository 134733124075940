import React from 'react';
import { Box, useTheme, Button } from '@mui/material';
import { CloudImage, Container, GridContainer, MarkDown } from 'components';
import { FiMessageCircle, FiArrowRight } from 'react-icons/fi'; // Import from react-icons
import AskIndmenButton from './ask-indemn-button';
import * as constants from 'utils/constants';
import AnnouncementBar from './announcement-bar';

interface TSectionHero1 {
  title: string;
  content: string;
  media?: string;
  message?: string;
  payload?: string;
}

const SectionHero1 = ({
  title,
  content,
  media,
  message,
  payload,
}: TSectionHero1): JSX.Element => {
  const { typography } = useTheme();

  return (
    <GridContainer display="flex" flexDirection="column" pt={25} gap={4}>
      <Container
        display="flex"
        flexDirection="column"
        alignItems="left"
        gap={4}
        width={{ xs: 1, md: 7 / 8, xl: 6 / 12 }}
      >
        <MarkDown
          sx={{
            ...typography['3xl'],
            ...typography.bold,
            whiteSpace: 'pre-line',
            textAlign: 'left',
          }}
          text={title}
        />
        <MarkDown
          sx={{
            ...typography.lg,
            ...typography.regular,
            textAlign: 'left',
          }}
          text={content}
        />
        <Button
          variant="contained"
          endIcon={<FiArrowRight />}
          size="large"
          color="primary"
          sx={{ width: 'fit-content' }}
          href="/pricing"
        >
          {constants.PAGE_CONTENT.GLOBAL_CTA_LABEL.MAIN}
        </Button>
        {/* <AskIndmenButton
          variant="contained"
          endIcon={<FiMessageCircle />}
          size="large"
          sx={{ width: 'fit-content' }}
          message={message}
          payload={payload}
        >
          Partner with us
        </AskIndmenButton> */}
      </Container>
      {!!media && (
        <Box width={{ xs: 1, xl: 12 / 12 }} margin="0 auto">
          <CloudImage id={media} />
        </Box>
      )}
    </GridContainer>
  );
};

export default SectionHero1;
