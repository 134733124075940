import React from 'react';
import { BoxProps, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Container } from 'components';

interface TAnnouncementBar {
  cta?: string,
  href?: string
  text: string
}

const AnnouncementBar = ({ cta, href, text, ...boxProps }: TAnnouncementBar & BoxProps): JSX.Element => {
  const { typography, breakpoints } = useTheme();

  const isMd = useMediaQuery(breakpoints.up('md'), {
    defaultMatches: true,
  });

  const handleClick = () => {
    if (href) {
      window.location.href = href;
    }
  };

  return (
    <Container
      {...boxProps}
      sx={{
        backdropFilter: 'blur(12px)',
        borderRadius: 2,
        background: 'rgba(233, 213, 255, 0.64)',
        border: '1px solid rgba(233, 213, 255, 0.80)',
        // background: 'conic-gradient(from 36deg at 27.29% 100%, rgba(67, 56, 202, 0.10) 0deg, rgba(37, 99, 235, 0.10) 360deg, rgba(37, 99, 235, 0.10) 360deg), radial-gradient(92.34% 92.26% at 67.14% 0%, rgba(139, 92, 246, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), linear-gradient(356deg, #0354CD 4.57%, #000 95.97%)',
        gap: { xs: 4.5, xl: 3 },
        display: 'grid',
        gridTemplateColumns: '1fr max-content',
        alignItems: 'center',
        justifyContent: 'center',
        px: { xs: 1, md: 2, xl: 3 },
        py: { xs: 1.5, md: 1, xl: 1 },
        cursor: { xs: 'pointer', md: 'default' },
        ...boxProps.sx
      }}
      onClick={isMd ? undefined : handleClick}
    >
      <Typography sx={{ ...typography.base, ...typography.regular }} color="#3b0764">
        {text}
      </Typography>
      <Button
        variant="outlined"
        size="small"
        sx={{
          color: '#3b0764',
          background: 'white',
          border: '1px solid rgba(233, 213, 255, 1)',
          display: { xs: 'none', md: 'block' },
          '&:hover':{
            background: '#6b21a8',
            color: 'white',
            border: '1px solid #7e22ce'
          }
        }}
        onClick={handleClick}
      >
        {cta}
      </Button>
    </Container>
  );
};

export default AnnouncementBar;
