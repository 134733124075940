import React from 'react';
import { GridContainer, Container, Image } from 'components';
import { useTheme, Typography, Box, Button } from '@mui/material';
import { FiMessageCircle, FiArrowRight } from 'react-icons/fi';
import AskIndmenButton from 'views/components/ask-indemn-button';
import ChatLoader from 'context/chatLoader';
import * as constants from 'utils/constants';
import { AnnouncementBar } from 'views/components';

const Hero = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <>
      {/* hero background */}
      <Image
        src={constants.CLOUDINARY_IMAGES.HOME_HERO_IMAGE}
        alt="home-hero-image"
        sx={{
          position: 'absolute',
          width: {
            xs: '60vh',
            sm: '70vh',
            md: '80vh',
            lg: '100vh',
            xl: '125vh',
          },
          height: {
            xs: '60vh',
            sm: '70vh',
            md: '80vh',
            lg: '100vh',
            xl: '125vh',
          },
          right: { xs: '-33%', md: '-25%', xl: '-20%' },
          top: { xs: '-20vh', md: '-25vh', xl: '-30vh' },
        }}
        imageProps={{
          style: {
            opacity: 0.56,
            objectFit: 'cover',
            mixBlendMode: 'hard-light',
          },
        }}
      />
      <GridContainer
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', xl: 'row' },
          gap: { xs: 6, md: 8, xl: 0.5 },
          pt: { xs: '25vh', md: '20vh', xl: '25vh' },
          height: { xl: '100vh' },
          position: 'relative'
        }}
      >
        <AnnouncementBar
          text="Read how GIC Underwriters automated 46% of broker questions in 60 days"
          cta="Read case study"
          href="/case-studies/gic-underwriters"
          sx={{
            position: 'absolute',
            top: { xs: 104, md: 120, xl: 136 },
            left: { xs: 16, md: 32, xl: 0 },
            right: { xs: 16, md: 32, xl: 0 }
          }}
        />

        <Container
          display="flex"
          flexDirection="column"
          gap={4}
          width={{ xs: 1, md: 7 / 8, xl: 7 / 12 }}
        >
          <Typography
            color="primary.900"
            sx={{
              fontSize: {
                xs: typography['2xl'].fontSize,
                md: typography['4xl'].fontSize,
              },
              ...typography.bold,
            }}
          >
            Lead the next revolution in insurance sales and service with GenAI
          </Typography>
          <Typography
            variant="h5"
            color="primary.900"
            sx={{ ...typography.lg, ...typography.regular }}
          >
            Build powerful AI agents that optimize your team’s operations,
            through the only insurance-focused, human-in-the-loop platform built
            by insurance experts.
          </Typography>
          <Button
            variant="contained"
            endIcon={<FiArrowRight />}
            size="large"
            color="primary"
            sx={{ width: 'fit-content' }}
            href="/pricing"
          >
            {constants.PAGE_CONTENT.GLOBAL_CTA_LABEL.MAIN}
          </Button>
          {/* <AskIndmenButton
            variant="contained"
            endIcon={<FiMessageCircle />}
            size="large"
            sx={{ width: 'fit-content' }}
            message="I’d like to see what AI solution might fit my needs"
          >
            Learn more
          </AskIndmenButton> */}
          <Box
            display="grid"
            gridTemplateColumns="max-content 1fr"
            gap={1.5}
            alignItems="baseline"
          >
            <AskIndmenButton
              variant="contained"
              endIcon={<FiMessageCircle size={12} />}
              size="small"
              color="secondary"
              message="Does this really work?"
            >
              Ask Indemn
            </AskIndmenButton>
            <Typography
              color="primary.900"
              sx={{ ...typography.xs, ...typography.medium }}
            >
              Does this really work?
            </Typography>
          </Box>
        </Container>
        <Container
          sx={{
            height: '70vh',
            width: { xs: 1, xl: 6 / 12 },
          }}
        >
          <ChatLoader />
        </Container>
      </GridContainer>
    </>
  );
};

export default Hero;
