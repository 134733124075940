import React from 'react';
import { Button, useTheme } from '@mui/material';
import { CloudImage, Container, GridContainer, MarkDown } from 'components';
import { FiArrowRight } from 'react-icons/fi'; // Importing the icon from react-icons

interface TSectionFeatureLarge {
  title: string;
  content: string;
  cta?: string;
  media?: string;
}

const SectionFeatureLarge = ({
  title,
  content,
  cta,
  media,
}: TSectionFeatureLarge): JSX.Element => {
  const { typography } = useTheme();

  return (
    <GridContainer
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      gap={{ xs: 4, md: 0.5 }}
    >
      <Container
        display="flex"
        flexDirection="column"
        gap={4}
        width={{ xs: 1, md: 3 / 8, xl: 5 / 12 }}
      >
        <MarkDown
          sx={{ ...typography['2xl'], ...typography.bold }}
          text={title}
        />
        <MarkDown
          sx={{ ...typography.base, ...typography.regular }}
          text={content}
        />
        {!!cta && (
          <Button
            variant="outlined"
            endIcon={<FiArrowRight />}
            href=""
            color="inherit"
            size="medium"
            sx={{ width: 'fit-content' }}
          >
            {cta}
          </Button>
        )}
      </Container>
      {
        media && (
          <Container width={{ xs: 1, md: 5 / 8, xl: 7 / 12 }}>
            <CloudImage id={media} />
          </Container>
        )
      }
    </GridContainer>
  );
};

export default SectionFeatureLarge;
