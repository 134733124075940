import React from 'react';
import { Box } from '@mui/material';
import Main from 'layouts/main';
import * as constants from 'utils/constants';
import { generateMetaTags } from '../pageHelmet';
import { BotHostContainer, Faq, Pricing } from 'views/components';
import ChatLoader from 'context/chatLoader';
import { ChatbotStoreProvider } from 'context/chatbot-state';

const faqs = [
  {
    faq_intent: 'Do you offer a free trial?',
    question: 'Do you offer a free trial?',
  },
  {
    faq_intent: 'What kind of results will I start seeing?',
    question: 'What kind of results will I start seeing?',
  },
  {
    faq_intent: 'How do I make a case to others in my org?',
    question: 'How do I make a case to others in my org?',
  }
];

const Price = (): JSX.Element => {
  return (
    <ChatbotStoreProvider>
      <Main>
        {generateMetaTags(
          constants.PAGE_CONTENT.PRICING.TITLE,
          constants.PAGE_CONTENT.PRICING.DESCRIPTION,
          constants.PAGE_CONTENT.PRICING.OG_IMAGE,
          window.location.href,
          constants.PAGE_CONTENT.PRICING.KEYWORDS,
        )}
        <Box pt={20}>
          <Pricing />
        </Box>
        <Faq faqs={faqs} />
        <BotHostContainer width={{ xs: 1, xl: 12 / 12 }} />
        <ChatLoader />
      </Main>
    </ChatbotStoreProvider>
  );
};

export default Price;
