export { default as About } from './about';
export { default as Home } from './home';
export { default as GetInsurance } from './get-insurance';
export { default as NotFound } from './not-found';
export { default as Venue } from './venues';
export { default as CaseStudy } from './case-studies';
export { default as Announcement } from './announcement';
export { default as Copilot } from './products/copilot';
export { default as AIStudio } from './products/acquisition';
export { AgencyBroker } from './solutions';
export { MGACarriers } from './solutions';
export { Enterprise } from './solutions';
export { BrokerPortal } from './solutions';
export { AICsr } from './solutions';
export { LeadCapture } from './solutions';
export { OnlineQuotesPayments } from './solutions';
export { default as DataPrivacy } from './data-privacy';
export { default as Pricing } from './pricing';

// Point of Sale
export { default as Pos } from './point-of-sale';
