import React from 'react';
import { useParams } from 'react-router-dom';
import GicCaseStudy from './gic-underwriters';
import ReuseTemplate from './template';
import NotFound from 'views/not-found';

const CaseStudy = (): JSX.Element => {
  const { slug } = useParams();

  switch (slug) {
    /*
    case 'ReuseTemplate':
      return <GobedegookCaseStudy />;
    */

    case 'gic-underwriters':
      return <GicCaseStudy />;

    default:
      return <NotFound />;
  }

};

export default CaseStudy;