import React from 'react';
import { useTheme, BoxProps } from '@mui/material';
import { Container, MarkDown } from 'components';

interface PurpleBoxProp {
  content: string
}

const PurpleBox = ({ content }: PurpleBoxProp & BoxProps): JSX.Element => {
  const { typography } = useTheme();

  return (
    <Container
      sx={{
        borderRadius: 2,
        border: '1px solid #828DF8',
        background: '#5048E5',
        boxShadow: '0px 0px 16px 4px rgba(88, 28, 135, 0.25)',
        pl: { xs: 3, md: 4, xl: 6 }
      }}
      my={4}
    >
      <MarkDown
        sx={{
          fontSize: { xs: typography.lg.fontSize, xl: typography.xl.fontSize },
          ...typography.regular
        }}
        color="white"
        text={content}
      />
    </Container>
  );
};

export default PurpleBox;
