import React from 'react';
import { useTheme, Typography, Box, Stack } from '@mui/material';
import { GridContainer, Container, Image, MarkDown } from 'components';
import * as constants from 'utils/constants';

interface TCaseStudyHero {
  title: string
  description: string
  orgLogo: string
}

const Hero = ({ title, description, orgLogo }: TCaseStudyHero): JSX.Element => {
  const { typography, palette } = useTheme();

  return (
    <>
      {/* hero background */}
      <Image
        src={constants.CLOUDINARY_IMAGES.PARTNER_US_HERO_IMAGE}
        alt="partner-us-hero-image"
        sx={{
          position: 'absolute',
          width: { xs: '116vh', md: '112vh', xl: '140vh' },
          height: { xs: '116vh', md: '112vh', xl: '140vh' },
          right: { xl: '-37.5vh' },
          top: { xs: '16vh', md: '12vh', xl: '-35vh' },
        }}
        imageProps={{
          style: {
            objectFit: 'cover',
            mixBlendMode: 'darken',
            opacity: 0.32,
          },
        }}
      />

      <GridContainer
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', xl: 'row' },
          gap: { xs: 4, xl: 0.5 },
          pt: 18,
          alignItems: 'center'
        }}
      >
        <Stack width={{ xs: 1, md: 6 / 8, xl: 8 / 12 }}>
          <Container>
            <Typography
              color="primary.900"
              sx={{ ...typography.sm, ...typography.regular }}
            >
              {title}
            </Typography>
          </Container>
          <Container>
            <MarkDown
              sx={{
                fontSize: { xs: typography['xl'].fontSize, md: typography['2xl'].fontSize, xl: typography['3xl'].fontSize },
                ...typography.bold,
              }}
              strongColor={palette.secondary[600]}
              text={description}
            />
          </Container>
        </Stack>
        <Box
          sx={{
            mt: { xl: 3 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: 'fit-content',
            gap: 3,
            width: { xs: 1, md: 6 / 8, xl: 4 / 12 },
            py: 4,
            borderRadius: 3,
            border: '1px solid rgba(255, 255, 255, 0.60)',
            background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0.48) 100%)',
            backdropFilter: 'blur(16px)'
          }}
        >
          <Container>
            <Box
              component="img"
              src={orgLogo}
              alt="Indemn"
              sx={{
                p: 3,
                width: 256,
                borderRadius: 3,
                border: '1px solid #E2E8F0',
                background: 'white'
              }}
            />
          </Container>
          <Container>
            <Box
              component="img"
              src={constants.CLOUDINARY_INDEMN_LOGOS.INDEMN_LOGO}
              alt="Indemn"
              sx={{
                width: 216,
              }}
            />
          </Container>
        </Box>
      </GridContainer>
    </>
  );
};

export default Hero;
