import React from 'react';
import {
  About as AboutView,
  Venue as VenueView,
  Home as HomeView,
  NotFound as NotFoundView,
  Pos as Pos,
  GetInsurance as GetInsuranceView,
  Copilot as CopilotView,
  AIStudio as AIStudioView,
  AgencyBroker as AgencyBrokerView,
  MGACarriers as MGACarriersView,
  Enterprise as EnterpriseView,
  BrokerPortal as BrokerPortalView,
  AICsr as AICsrView,
  LeadCapture as LeadCaptureView,
  OnlineQuotesPayments as OnlineQuotesPaymentsView,
  DataPrivacy as DataPrivacyView,
  Pricing as PricingView,
  CaseStudy as CaseStudyView,
  Announcement as AnnouncementView,
} from 'views';

// ensure wedding view is live in prod

const routes = [
  {
    path: '/',
    renderer: (params = {}): JSX.Element => <HomeView {...params} />,
  },
  {
    path: '/venues/:slug',
    renderer: (params = {}): JSX.Element => <VenueView {...params} />,
  },
  {
    path: '/case-studies/:slug',
    renderer: (params = {}): JSX.Element => <CaseStudyView {...params} />,
  },
  {
    path: '/news/:slug',
    renderer: (params = {}): JSX.Element => <AnnouncementView {...params} />,
  },
  {
    path: '/about',
    renderer: (params = {}): JSX.Element => <AboutView {...params} />,
  },
  {
    path: '/pricing',
    renderer: (params = {}): JSX.Element => <PricingView {...params} />,
  },
  {
    path: '/point-of-sale',
    renderer: (params = {}): JSX.Element => <Pos {...params} />,
  },
  {
    path: '/get-insurance/:slug',
    renderer: (params = {}): JSX.Element => <GetInsuranceView {...params} />,
  },
  {
    path: '/solutions/broker-portal',
    renderer: (params = {}): JSX.Element => <BrokerPortalView {...params} />,
  },
  {
    path: '/solutions/ai-csr',
    renderer: (params = {}): JSX.Element => <AICsrView {...params} />,
  },
  {
    path: '/solutions/lead-capture',
    renderer: (params = {}): JSX.Element => <LeadCaptureView {...params} />,
  },
  {
    path: '/solutions/online-quotes-payments',
    renderer: (params = {}): JSX.Element => (
      <OnlineQuotesPaymentsView {...params} />
    ),
  },
  {
    path: '/solutions/agents-brokers',
    renderer: (params = {}): JSX.Element => <AgencyBrokerView {...params} />,
  },
  {
    path: '/solutions/mga-insurers',
    renderer: (params = {}): JSX.Element => <MGACarriersView {...params} />,
  },
  {
    path: '/solutions/enterprise',
    renderer: (params = {}): JSX.Element => <EnterpriseView {...params} />,
  },
  {
    path: '/products/agent-copilot',
    renderer: (params = {}): JSX.Element => <CopilotView {...params} />,
  },
  {
    path: '/products/ai-studio',
    renderer: (params = {}): JSX.Element => <AIStudioView {...params} />,
  },
  {
    path: '/data-privacy',
    renderer: (params = {}): JSX.Element => <DataPrivacyView {...params} />,
  },
  {
    path: '/not-found',
    renderer: (params = {}): JSX.Element => <NotFoundView {...params} />,
  },
];

export default routes;
