import React from 'react';
// import { useParams } from 'react-router-dom';
import Main from 'layouts/main';
import { ChatbotStoreProviderOld } from 'context/chatbot-state-old';
import Hero from './hero';
import { Faq } from 'views/components';
import News from './news';

const faqs = [
  {
    faq_intent: 'Will it also take me 7 days to go live with Indemn?',
    question: 'Will it also take me 7 days to go live with Indemn?',
  },
  {
    faq_intent: 'What will I need to set up my broker portal assistant?',
    question: 'What will I need to set up my broker portal assistant?',
  },
  {
    faq_intent: 'How much does a subscription to the Indemn platform cost?',
    question: 'How much does a subscription to the Indemn platform cost?',
  },
  {
    faq_intent: 'What kind of support will I get after signing up for the Indemn platform?',
    question: 'What kind of support will I get after signing up for the Indemn platform?',
  },
  {
    faq_intent: 'I only have prior recordings of phone calls.  Will that work as a knowledge base?',
    question: 'I only have prior recordings of phone calls.  Will that work as a knowledge base?',
  },
  {
    faq_intent: 'Do you provide AI agents for phone communications?',
    question: 'Do you provide AI agents for phone communications?',
  },
];

const Announcement = (): JSX.Element => {
  // const { slug } = useParams();

  return (
    <ChatbotStoreProviderOld>
      <Main>
        <Hero />
        <News />
        <Faq faqs={faqs} />
      </Main>
    </ChatbotStoreProviderOld>
  );
};

export default Announcement;