import React, { useState, useMemo } from 'react';
import {
  Box,
  AppBar,
  Hidden,
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
  Typography,
  Stack,
} from '@mui/material';
import { Logo, GridContainer, Container, Image } from 'components';
// import mixpanel from 'mixpanel-browser';
import { FiMessageCircle, FiArrowRight, FiX, FiMenu } from 'react-icons/fi';
// import { PageContext } from 'context/page-state';
import AskIndmenButton from 'views/components/ask-indemn-button';
import NavMenu from 'layouts/components/nav-menu';
import NavCollapse from 'layouts/components/nav-collapse';
import * as constants from 'utils/constants';

// import { parseNavItem } from 'utils/helper-generic';
// mixpanel.init('f8fc6cf912ff3cdf25b9af5ce7ed3dd9', { debug: true });

const solutions = [
  {
    category: 'BY SIZE',
    items: [
      {
        slug: '/solutions/agents-brokers',
        icon: constants.CLOUDINARY_INDEMN_LOGOS.SOLUTIONS_FOR_AGENCIES_LOGO,
        title: 'For Agents and Brokers',
      },
      {
        slug: '/solutions/mga-insurers',
        icon: constants.CLOUDINARY_INDEMN_LOGOS.SOLUTIONS_FOR_MGAS_LOGO,
        title: 'For MGAs and Insurers',
      },
      {
        slug: '/solutions/enterprise',
        icon: constants.CLOUDINARY_INDEMN_LOGOS
          .SOLUTIONS_FOR_LARGER_AGENCY_LOGO,
        title: 'For Large Enterprises',
      },
    ],
  },
  {
    category: 'BY USE CASE',
    items: [
      {
        slug: '/solutions/broker-portal',
        icon: constants.CLOUDINARY_INDEMN_LOGOS.SOLUTIONS_BROKER_LOGO,
        title: 'Broker Portal Assistant',
        description:
          'Save time by rapidly answering 50% of all service requests from your appointed agents',
      },
      {
        slug: '/solutions/ai-csr',
        icon: constants.CLOUDINARY_INDEMN_LOGOS.SOLUTIONS_CSR_LOGO,
        title: 'AI CSRs',
        description:
          'Deploy multilingual AI agents across channels, available 24/7 and improving every day',
      },
      {
        slug: '/solutions/lead-capture',
        icon: constants.CLOUDINARY_INDEMN_LOGOS.SOLUTIONS_LEAD_LOGO,
        title: 'Lead Capture and Workflow Agents',
        description:
          'Convert more users, get insurance applications completed,  renew and round out accounts',
      },
      {
        slug: '/solutions/online-quotes-payments',
        icon: constants.CLOUDINARY_INDEMN_LOGOS.SOLUTIONS_QUOTE_LOGO,
        title: 'Online Quotes, Underwriting, and Payments',
        description:
          'The full digital distribution stack to replace your webform and drive 10X more online purchases',
      },
    ],
  },
];

const products = [
  {
    slug: '/products/ai-studio',
    icon: constants.CLOUDINARY_INDEMN_LOGOS.PRODUCT_ACQUISITION_LOGO,
    title: 'AI Studio',
    description:
      'A complete toolkit to build AI agents that are highly customized to any use case within your insurance stack',
  },
  {
    slug: '/products/agent-copilot',
    icon: constants.CLOUDINARY_INDEMN_LOGOS.PRODUCT_COPILOT_LOGO,
    title: 'Agent co-pilot',
    description:
      'An AI-powered platform for CSRs to intervene in live conversations for high-value prospects',
  },
  // {
  //   slug: '/distribution',
  //   icon: '/images/products/distribute.svg',
  //   title: 'Distribute with Indemn',
  //   description: 'Strategic pieces to distribute Insurance via Affinity and Embedded channels'
  // }
];

const Topbar = (): JSX.Element => {
  const { typography, palette, breakpoints } = useTheme();

  // const pageCtx = useContext(PageContext);
  // const { events } = pageCtx.pageState;

  const [openNavigationMenu, setOpenNavigationMenu] = useState<boolean>(false);

  const isXl = useMediaQuery(breakpoints.up('xl'), {
    defaultMatches: true,
  });

  const handleOpenNavigationMenu = () => {
    setOpenNavigationMenu((pre) => !pre);
  };

  const productList = useMemo(
    () =>
      products.map(({ icon, title, description, slug }) => (
        <Button
          key={slug}
          size="medium"
          href={`${slug}`}
          sx={{
            display: 'grid',
            gridTemplateColumns: 'max-content minmax(0, 1fr)',
            gap: 3,
            alignItems: { xs: 'center', xl: 'flex-start' },
            padding: { xl: '12px 16px' },
          }}
        >
          <Image
            src={icon}
            alt={slug}
            width={{ xs: 32, xl: 64 }}
            height={{ xs: 32, xl: 64 }}
            imageProps={{
              style: { objectFit: 'cover' },
            }}
          />
          <Box display="flex" gap={0.75} flexDirection="column">
            <Typography
              color="primary.800"
              sx={{ ...typography.sm, ...typography.semibold }}
            >
              {title}
            </Typography>
            <Typography
              color="monochrome.gray.700"
              sx={{
                ...typography.xs,
                ...typography.regular,
                display: { xs: 'none', xl: 'block' },
                whiteSpace: 'break-spaces',
              }}
            >
              {description}
            </Typography>
          </Box>
        </Button>
      )),
    [],
  );

  const solutionList = useMemo(
    () =>
      solutions.map(({ category, items }) => (
        <Stack gap="18px" key={category}>
          <Typography
            color="monochrome.gray.600"
            sx={{
              ...typography.sm,
              ...typography.regular,
            }}
          >
            {category}
          </Typography>
          <Stack gap={0.5}>
            {items.map(({ icon, title, description, slug }) => (
              <Button
                key={slug}
                size="medium"
                href={`${slug}`}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'max-content minmax(0, 1fr)',
                  gap: 1,
                  alignItems: { xs: 'center', xl: 'flex-start' },
                  padding: { xl: '6px 8px' },
                  ml: { xl: -1 },
                }}
              >
                <Image
                  src={icon}
                  width={24}
                  height={24}
                  imageProps={{
                    style: { objectFit: 'cover' },
                  }}
                />
                <Stack gap={0.75}>
                  <Typography
                    color="primary.800"
                    sx={{ ...typography.sm, ...typography.semibold }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    color="monochrome.gray.700"
                    sx={{
                      ...typography.xs,
                      ...typography.regular,
                      display: { xs: 'none', xl: 'block' },
                      whiteSpace: 'break-spaces',
                    }}
                  >
                    {description}
                  </Typography>
                </Stack>
              </Button>
            ))}
          </Stack>
        </Stack>
      )),
    [],
  );

  // const insuranceList = useMemo(() => events.map(({ slug, list_title }) => {
  //   const { text, icon, tag } = parseNavItem(list_title);

  //   return (
  //     <Box display='flex' gap={1.5} alignItems='center' key={slug}>
  //       <Button
  //         variant='text'
  //         size='small'
  //         endIcon={<FeatherIcon icon={icon} />}
  //         href={`/get-insurance/${slug}`}
  //       >
  //         <MarkDown sx={{ ...typography.sm, ...typography.medium }} text={text} />
  //       </Button>
  //       {!!tag &&
  //         <Box display='flex' gap={0.5} alignItems='center' padding='4px 6px'>
  //           <Image
  //             src={`/images/icons/${tag}.svg`}
  //             width={12}
  //             height={12}
  //             display='flex'
  //             imageProps={{
  //               style: { objectFit: 'cover' }
  //             }}
  //           />
  //           <Typography color='secondary.500' sx={{ ...typography.xs, ...typography.light }}>
  //             Most {tag}
  //           </Typography>
  //         </Box>
  //       }
  //     </Box>
  //   );
  // }), [events]);

  return (
    <AppBar
      position="fixed"
      sx={{
        top: { xs: 16, md: 32 },
        background: 'transparent',
        boxShadow: 'none',
      }}
    >
      <GridContainer pb={0}>
        <Container
          sx={{
            width: 1,
            background: openNavigationMenu
              ? palette.background.navMenu
              : palette.background.header,
            float: 'right',
            borderRadius: '12px',
            border: '1px solid rgba(255, 255, 255, 0.60)',
            backdropFilter: 'blur(16px)',
            transition: 'all .2s ease-in-out',
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
          }}
        >
          <Box
            sx={{
              display: { xs: 'flex', xl: 'grid' },
              gridTemplateColumns: {
                xs: 'unset',
                xl: 'max-content 1fr max-content',
              },
              alignItems: 'center',
              justifyContent: { xs: 'space-between', xl: 'unset' },
            }}
          >
            <Logo />

            <Hidden xlDown>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={3}
              >
                <NavMenu label="Solutions" gap={6}>
                  {solutionList}
                </NavMenu>
                <NavMenu label="Products" padding="8px !important" gap={3}>
                  {productList}
                </NavMenu>
                <Button
                  variant="text"
                  size="medium"
                  href="/pricing"
                  sx={{ ...typography.sm }}
                >
                  Pricing
                </Button>
                <Button
                  variant="text"
                  size="medium"
                  href="/about"
                  sx={{ ...typography.sm }}
                >
                  About
                </Button>
              </Box>
            </Hidden>

            {isXl ? (
              <Button
                variant="contained"
                endIcon={<FiArrowRight />}
                size="medium"
                color="primary"
                sx={{ width: 'fit-content' }}
                href="/pricing"
              >
                {constants.PAGE_CONTENT.GLOBAL_CTA_LABEL.MAIN}
              </Button>
              // <AskIndmenButton
              //   variant="contained"
              //   endIcon={<FiMessageCircle />}
              //   size="medium"
              //   message="I'm looking to power my business through Indemn"
              // >
              //   Partner with us
              // </AskIndmenButton>
            ) : (
              <IconButton sx={{ p: 0.5 }} onClick={handleOpenNavigationMenu}>
                {openNavigationMenu ? (
                  <FiX color={palette.primary[800]} />
                ) : (
                  <FiMenu color={palette.primary[800]} />
                )}
              </IconButton>
            )}
          </Box>

          {openNavigationMenu && !isXl && (
            <>
              <Box
                sx={{
                  maxHeight: 'calc(100vh - 240px)',
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3,
                  alignItems: 'baseline',
                  padding: 0.5,
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  alignItems="baseline"
                >
                  <NavCollapse label="Solutions" pl={2}>
                    {solutionList}
                  </NavCollapse>
                  <NavCollapse label="Products" pl={0}>
                    {productList}
                  </NavCollapse>
                </Box>
                <Button
                  variant="text"
                  size="medium"
                  href="/pricing"
                  sx={{ ...typography.sm }}
                >
                  Pricing
                </Button>
                <Button
                  variant="text"
                  size="medium"
                  href="/about"
                  sx={{ ...typography.sm }}
                >
                  About
                </Button>
              </Box>

              <Button
                variant="contained"
                endIcon={<FiArrowRight />}
                size="medium"
                color="primary"
                href="/pricing"
              >
                {constants.PAGE_CONTENT.GLOBAL_CTA_LABEL.MAIN}
              </Button>

              {/* <AskIndmenButton
                variant="contained"
                endIcon={<FiMessageCircle />}
                size="medium"
                payload="/faqs__list"
              >
                Partner with us
              </AskIndmenButton> */}
            </>
          )}
        </Container>
      </GridContainer>
    </AppBar>
  );
};

export default Topbar;
